<template>
    <div class="widget__item widget__articles dashboard__block">
        <div class="articles__header">
            <h2 class="h2 articles__title">Статьи</h2>
            <perfect-scrollbar class="articles__tabs swiper-no-swiping">
                <div
                    :class="['articles__tab', {'articles__tab--active': articlesSelected === 'favourites'}, 'mr-20']"
                    @click="articlesSelected = 'favourites'"
                >
                    Избранные <span class="articles__tab-count">{{favourites.count}}</span>
                </div>

                <div
                    v-if="current_user.is_admin"
                    :class="['articles__tab', { 'articles__tab--active': articlesSelected === 'old' }, 'mr-20']"
                    @click="articlesSelected = 'old'"
                >
                    Устаревшие <span class="articles__tab-count">{{old.count}}</span>
                </div>

                <div
                    :class="['articles__tab', { 'articles__tab--active': articlesSelected === 'recommended' }]"
                    @click="articlesSelected = 'recommended'"
                >
                    Статьи для Вас <span class="articles__tab-count">{{ recommended.count }}</span>
                </div>
            </perfect-scrollbar>
        </div>

        <div class="articles__main">
            <ul class="articles__list" v-if="currentArticles.count > 0">
                <li
                    v-for="article in currentArticles.list"
                    class="articles__item"
                    :class="{
                        'articles__item--favourite': articlesSelected === 'favourites',
                        'articles__item--old': articlesSelected === 'old',
                        'articles__item--recommendation': articlesSelected === 'recommended',
                    }"
                >
                    <router-link
                        :to="article.link ? article.link : `knowledge/article/${article.id}`"
                        :title="article.title"
                        class="articles__link"
                    >
                        <span class="articles__link-icon"/>
                        <span class="articles__link-text">{{ article.title }}</span>
                    </router-link>
                </li>
            </ul>

            <div
                v-else
                class="articles__empty"
            >
                <h3 class="articles__empty-title">
                    Нет {{ articlesList[articlesSelected].decl }} статей
                </h3>
                <router-link to="/knowledge/" class="articles__empty-link">Перейти в базу знаний</router-link>
            </div>

            <router-link
                v-if="currentArticles.count > 4"
                :to="currentHref"
                class="articles__more"
            >
                Ещё
                <span class="articles__more-count">{{currentArticles.count - 4}}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'Articles',
        data() {
            return {
                articlesSelected: 'favourites',
                articlesList: {
                    favourites: {
                        label: 'Избранное',
                        decl: 'избранных'
                    },

                    old: {
                        label: 'Устаревшие',
                        decl: 'устаревших'
                    },

                    recommended: {
                        label: 'Статьи для Вас',
                        decl: 'для Вас'
                    }
                }
            };
        },
        computed: {
            currentArticles: function() {
                const types = {
                    favourites: this.favourites,
                    old: this.old,
                    recommended: this.recommended
                };

                return types[this.articlesSelected];
            },

            currentHref: function() {
                const articles = {
                    favourites: '/knowledge/favourites',
                    old: '/knowledge/old',
                    recommended: '/knowledge/recommended'
                };

                return articles[this.articlesSelected];
            },

            ...mapState('wrapper_materials', ['favourites', 'old', 'recommended']),
            ...mapState('default_data', ['current_user'])
        }
    };
</script>

<style lang="sass">
    @import "#sass/v-style"

    .widget
        &__articles
            display: flex
            flex-direction: column
            min-height: 350px
            .articles
                &__item
                    display: flex
                    align-items: center
                    padding-bottom: 18px
                    padding-top: 13px
                    border-bottom: 1px solid $border-gray

                    &--favourite
                        .articles__link
                            &-icon
                                background-image: $star-icon
                                @include hard-size(15px,15px)
                    &--old
                        .articles__link
                            color: $text-darkgray
                            &:hover
                                color: $link-main
                            &-icon
                                @include hard-size(5px,5px)
                                background: #eb5757
                                border-radius: 50%

                    &:last-of-type
                        padding-bottom: 0
                        border-bottom: none

                &__header
                    display: flex
                    flex-direction: column
                    justify-content: space-between

                &__tabs
                    width: 100%
                    display: flex
                    padding: 2px 0
                    overflow-x: auto
                    margin-top: 10px
                    line-height: 22px
                    list-style-type: none
                    border-bottom: 1px solid $border-gray
                    .ps__rail-x
                        height: 8px
                        .ps__thumb-x
                            top: 0
                            bottom: 0
                            height: 6px !important
                            margin: auto

                &__tab
                    color: $text-gray
                    position: relative
                    text-transform: uppercase
                    font-size: 0.875rem
                    font-weight: 600
                    letter-spacing: 0.06em
                    cursor: pointer
                    white-space: nowrap
                    user-select: none

                    &:hover
                        color: $text-darkgray

                    &--active
                        color: #000
                        &:hover
                            color: #000

                        &::before
                            content: ''
                            position: absolute
                            height: 3px
                            width: 100%
                            bottom: -1px
                            left: 0
                            background: $gold

                    &-count
                        font-size: 0.75rem
                        color: $text-gray
                        margin-left: 5px
                &__main
                    margin-top: 20px
                &__list
                    list-style-type: none
                    padding-left: 0
                &__link
                    font-size: 1rem
                    color: #000
                    overflow: hidden
                    white-space: nowrap
                    text-overflow: ellipsis
                    display: flex
                    align-items: center
                    &-icon
                        margin-right: 10px
                        background-repeat: no-repeat
                        background-size: cover
                    &-text
                        overflow: hidden
                        text-overflow: ellipsis
                    &:hover
                        color: #1568ac

                &__more
                    color: $link-main
                    font-size: 0.875rem
                    margin-top: 10px
                    display: inline-block

                &__old
                    .articles__link
                        color: $text-gray

                &__empty
                    text-align: center
                    display: flex
                    flex-direction: column
                    justify-content: center
                    align-items: center
                    height: 100%
                    &-title
                        color: $text-gray
                        margin:
                            bottom: 0
                            top: auto
                    &-link
                        margin-top: auto

            @media (max-width: 500px)
                .articles
                    &__header
                        flex-direction: column
                        align-items: flex-start
                    &__tabs
                        margin-top: 10px
                    &__tab
                        font-size: 0.75rem
                    &__item
                        padding-bottom: 10px
                    &__list
                        margin-top: 10px
                    &__empty
                        &-title
                            font-size: 1.25rem
                        &-link
                            font-size: 0.875rem

</style>
